<template>
    <div class="inner-section">
           <card>
             <template v-slot:searchHeaderTitle>
                <h4 class="card-title">{{ $t('tpm_report.trainer_trainee_info') }} {{ $t('globalTrans.report') }} {{ $t('globalTrans.search') }}</h4>
              </template>
              <template v-slot:searchBody>
                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                      <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                        <b-row>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Trainer/Trainee"  vid="trainer_trainee" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="trainer_trainee"
                                   slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.trainer') + '/' + $t('elearning_config.trainee')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="search.trainer_trainee"
                                  :options="userTypeList"
                                  id="trainer_trainee"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="org_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="search.org_id"
                                  :options="orgList"
                                  id="org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-overlay :show="loading">
                          <b-row>
                            <b-col lg="6" sm="6">
                            <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="fiscal_year_id"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.fiscal_year')}}
                                </template>
                                <b-form-select
                                  plain
                                  v-model="search.fiscal_year_id"
                                  :options="fiscalYearList"
                                  id="fiscal_year_id"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                              </b-form-group>
                            </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                            <ValidationProvider name="Circular Memo No"  vid="circular_memo_no">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="circular_memo_no"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_iabm.circular_memo_no')}}
                                </template>
                                <b-form-select
                                  plain
                                  v-model="search.circular_memo_no"
                                  :options="circularList"
                                  id="circular_memo_no"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                              </b-form-group>
                            </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Training Title" vid="training_title_id">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="training_title_id"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_config.training_title')}}
                                  </template>
                                  <b-form-select
                                    plain
                                    v-model="search.training_title_id"
                                    :options="trainingTitleList"
                                    id="training_title_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Batch" vid="batch_id">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="batch_id"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_config.batch')}}
                                  </template>
                                  <b-form-select
                                    plain
                                    v-model="search.batch_id"
                                    :options="batchList"
                                    id="batch_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                          </b-row>
                        </b-overlay>
                        <div class="row">
                          <b-col sm="9">
                          </b-col>
                          <b-col sm="3" style="text-align: right;">
                            <b-button size="sm" type="submit" variant="primary" class="mt-20">
                              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                            </b-button>
                          </b-col>
                        </div>
                      </b-form>
                    </ValidationObserver>
              </template>
          </card>
          <b-row>
        <b-col md="12" v-if="showData">
          <b-overlay>
            <b-row>
              <b-col md="12">
                <body-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">
                      {{ $t('tpm_report.trainer_trainee_info') }} {{ $t('globalTrans.report') }} {{ $t('globalTrans.list') }}
                    </h4>
                  </template>
                  <template v-slot:headerAction>
                    <b-button v-if="finalList.length === undefined" variant="primary" size="sm" @click="pdfExport" class="float-right">
                        {{  $t('globalTrans.export_pdf') }}
                    </b-button>
                  </template>
                  <template v-slot:body>
                    <b-overlay :show="customloading">
                      <list-report-head :baseUrl="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="search.org_id ? search.org_id : 12">
                        <template v-slot:projectNameSlot>
                          {{ }}
                        </template>
                        {{ $t('tpm_report.trainer_trainee_info') }} {{ $t('globalTrans.report') }}
                      </list-report-head>
                      <b-row>
                        <b-col md="3">
                          <span>{{ $t('elearning_config.fiscal_year') }}</span>:
                          <strong>{{ search.fiscal_year_id ? ($i18n.locale === 'en' ? searchHeaderData.fiscal_year_en : searchHeaderData.fiscal_year_bn) : $t('globalTrans.all') }}</strong>
                        </b-col>
                        <b-col md="3" class="text-center">
                          <span>{{ $t('elearning_config.organization') }}</span>:
                          <strong>{{ search.org_id ? ($i18n.locale === 'en' ? searchHeaderData.org_en : searchHeaderData.org_bn) : $t('globalTrans.all') }}</strong>
                        </b-col>
                        <b-col md="3" class="text-right">
                          <span>{{ $t('elearning_iabm.circular_memo_no') }}</span>:
                          <strong>{{ search.circular_memo_no ? search.circular_memo_no : $t('globalTrans.all') }}</strong>
                        </b-col>
                        <b-col md="3" class="text-right">
                          <span>{{ $t('elearning_config.training_title') }}</span>:
                          <strong>{{ search.training_title_id ? ($i18n.locale === 'en' ? searchHeaderData.training_title_en : searchHeaderData.training_title_bn) : $t('globalTrans.all') }}</strong>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="12" sm="12">
                          <table style="width:100%;">
                            <thead>
                              <tr>
                                <th>{{ $t('globalTrans.sl_no') }}</th>
                                <th>{{ $t('globalTrans.name') }}</th>
                                <th>{{ $t('globalTrans.organization') }}</th>
                                <th>{{ $t('globalTrans.designation') }}</th>
                                <th>{{ $t('globalTrans.mobile') }}</th>
                                <th>{{ $t('globalTrans.photo') }}</th>
                                <th>{{ $t('globalTrans.action') }}</th>
                              </tr>
                            </thead>
                            <tbody v-if="finalList.length">
                              <tr v-for="(item, index) in finalList" :key="index">
                                <td>{{ $n(index + 1, { useGrouping: false }) }}</td>
                                <td>{{ ($i18n.locale==='bn') ? item.name_bn : item.name }}</td>
                                <td>{{ ($i18n.locale==='bn') ? item.org_name_bn : item.org_name_en }}</td>
                                <td>{{ ($i18n.locale==='bn') ? item.designation_bn : item.designation_en }}</td>
                                <td>{{ item.mobile | mobileNumber }}</td>
                                <td>
                                  <b-img width='100px' :src="trainingElearningServiceBaseUrl+'storage/'+item.image" fluid alt="User Image"></b-img>
                                </td>
                                <td>
                                  <b-button v-b-modal.modal-details variant="iq-bg-success mr-1" size="sm" @click="detailData(item)" class="moc-action-btn moc-view-btn" :title="$t('globalTrans.view')"><i class="ri-eye-line"></i></b-button>                        </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td colspan="7" class="text-center"> {{ $t('fair_report.not_found') }} </td>
                              </tr>
                            </tbody>
                          </table>
                        </b-col>
                      </b-row>
                    </b-overlay>
                  </template>
                </body-card>
              </b-col>
            </b-row>
          </b-overlay>
        </b-col>
      </b-row>
          <!-- <body-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">
          {{ $t('tpm_report.trainer_trainee_info') }} {{ $t('globalTrans.report') }} {{ $t('globalTrans.list') }}
        </h4>
      </template>
      <template v-slot:headerAction>
        <b-button v-if="finalList.length" variant="primary" @click="pdfExport()" size="sm" class="float-right">
            {{  $t('globalTrans.export_pdf') }}
        </b-button>
      </template>
      <template v-slot:body>
        <b-overlay :show="customloading">
          <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="10">
            <template v-slot:projectNameSlot>
              {{ }}
            </template>
            {{ $t('tpm_report.trainer_trainee_info') }} {{ $t('globalTrans.report') }}
          </list-report-head>
          <b-row>
            <b-col md="3">
              <span>{{ $t('elearning_config.fiscal_year') }}</span>:
              <strong>{{ search.fiscal_year_id ? ($i18n.locale === 'en' ? searchHeaderData.fiscal_year_en : searchHeaderData.fiscal_year_bn) : $t('globalTrans.all') }}</strong>
            </b-col>
            <b-col md="3" class="text-center">
              <span>{{ $t('elearning_config.organization') }}</span>:
              <strong>{{ search.org_id ? ($i18n.locale === 'en' ? searchHeaderData.org_en : searchHeaderData.org_bn) : $t('globalTrans.all') }}</strong>
            </b-col>
            <b-col md="3" class="text-right">
              <span>{{ $t('elearning_iabm.circular_memo_no') }}</span>:
              <strong>{{ search.circular_memo_no ? search.circular_memo_no : $t('globalTrans.all') }}</strong>
            </b-col>
            <b-col md="3" class="text-right">
              <span>{{ $t('elearning_config.training_title') }}</span>:
              <strong>{{ search.training_title_id ? ($i18n.locale === 'en' ? searchHeaderData.training_title_en : searchHeaderData.training_title_bn) : $t('globalTrans.all') }}</strong>
            </b-col>
          </b-row>
          <b-row>
            <b-overlay>
              <b-row>
                <b-col lg="12" sm="12">
                  <table style="width:100%;">
                    <thead>
                      <tr>
                        <th>{{ $t('globalTrans.sl_no') }}</th>
                        <th>{{ $t('globalTrans.name') }}</th>
                        <th>{{ $t('globalTrans.organization') }}</th>
                        <th>{{ $t('globalTrans.designation') }}</th>
                        <th>{{ $t('globalTrans.mobile') }}</th>
                        <th>{{ $t('globalTrans.photo') }}</th>
                        <th>{{ $t('globalTrans.action') }}</th>
                      </tr>
                    </thead>
                    <tbody v-if="finalList.length">
                      <tr v-for="(item, index) in finalList" :key="index">
                        <td>{{ $n(index + 1, { useGrouping: false }) }}</td>
                        <td>{{ ($i18n.locale==='bn') ? item.name_bn : item.name }}</td>
                        <td>{{ ($i18n.locale==='bn') ? item.org_name_bn : item.org_name_en }}</td>
                        <td>{{ ($i18n.locale==='bn') ? item.designation_bn : item.designation_en }}</td>
                        <td>{{ item.mobile | mobileNumber }}</td>
                        <td>
                          <b-img width='100px' :src="trainingElearningServiceBaseUrl+'storage/'+item.image" fluid alt="User Image"></b-img>
                        </td>
                        <td>
                          <b-button v-b-modal.modal-details variant="iq-bg-success mr-1" size="sm" @click="detailData(item)" class="moc-action-btn moc-view-btn" :title="$t('globalTrans.view')"><i class="ri-eye-line"></i></b-button>                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="7" class="text-center"> {{ $t('fair_report.not_found') }} </td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-overlay>
          </b-row>
        </b-overlay>
      </template>
      <pre>{{ finalList.length }}</pre>
      <pre>{{ finalList }}</pre>
    </body-card> -->
        <b-modal id="modal-details" size="lg" :title="$t('globalTrans.details')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Details :item="itemData"/>
        </b-modal>
      </div>
  </template>
  <script>
  import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
  import { trainerTraineeInfoReportList, orgCircularList, circularData } from '../../api/routes'
  import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
  import Details from './Details'
  import ModalBaseMasterList from '@/mixins/list'
  // import Store from '@/store'
  export default {
      mixins: [ModalBaseMasterList],
      components: {
        ListReportHead, Details
      },
      data () {
          return {
            trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
            customloading: false,
            circularLoading: false,
            showData: false,
            sortBy: '',
            sortDirection: '',
            sortDesc: '',
            item: '',
            search: {
              trainer_trainee: 0,
              org_id: 0,
              batch_id: 0,
              training_title_id: 0,
              circular_memo_no: 0,
              fiscal_year_id: 0,
              limit: 20
            },
            searchHeaderData: {
              fiscal_year_en: '',
              fiscal_year_bn: '',
              org_en: '',
              org_bn: '',
              circular_memo_no: '',
              training_title_en: '',
              training_title_bn: ''
            },
            trainingCategoryList: [],
            itemData: [],
            circularList: [],
            batchList: [],
            finalList: [],
            labelData: [
            { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
            { labels: 'elearning_config.organization', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '35%' } },
            { labels: 'globalTrans.name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '35%' } },
            { labels: 'globalTrans.designation', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '35%' } },
            { labels: 'globalTrans.mobile', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '35%' } },
            { labels: 'globalTrans.photo', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '35%' } },
            { labels: 'globalTrans.action', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 9, thStyle: { width: '10%' } }
          ]
        }
      },
      computed: {
        userTypeList () {
          const userList = [
            { value: 1, text: this.$i18n.locale === 'en' ? 'Trainer' : 'প্রশিক্ষক', text_en: 'Trainer', text_bn: 'প্রশিক্ষক' },
            { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী', text_en: 'Trainee', text_bn: 'প্রশিক্ষণার্থী' }
          ]
          return userList
        },
        fiscalYearList: function () {
          return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
        },
        orgList: function () {
          return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
        },
        trainingTitleList: function () {
          return this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        },
        trainerEvaluationList: function () {
          return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.update')
        },
        columns () {
          const labelData = this.labelData
          const labels = labelData.map((item, index) => {
            return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
          })
            let keys = []
            if (this.$i18n.locale === 'bn') {
                keys = [
                  { key: 'serial' },
                  { key: 'org_bn' },
                  { key: 'name_bn' },
                  { key: 'designation_bn' },
                  { key: 'mobile' },
                  { key: 'photo' },
                  { key: 'action' }
                ]
            } else {
                keys = [
                  { key: 'serial' },
                  { key: 'org' },
                  { key: 'name' },
                  { key: 'designation_en' },
                  { key: 'mobile' },
                  { key: 'photo' },
                  { key: 'action' }
                ]
            }
            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        },
        pageOptions () {
          return this.$store.state.commonObj.pageOptions
        }
      },
      async created () {
        this.getCircularList()
          // this.search = Object.assign({}, this.search, {
          //   org_id: this.$store.state.dataFilters.orgId,
          //   fiscal_year_id: this.$store.state.currentFiscalYearId
          // })
          // this.loadData()
      },
      watch: {
        'search.fiscal_year_id': function (newValue) {
          this.getCircularList()
        },
        'search.circular_memo_no': function (newValue) {
          this.getCircularMemoNo(newValue)
        },
        'search.org_id': function (newValue) {
          this.batchList = this.getBatchList(newValue)
        }
      },
      methods: {
        detailData (item) {
            this.itemData = item
        },
        getBatchList (orgId) {
          const batchList = this.$store.state.TrainingElearning.commonObj.batchList.filter(item => item.status === 1)
          if (orgId) {
            return batchList.filter(item => item.org_id === orgId)
          }
          return batchList
        },
        async getCircularMemoNo (getCircularMemoNo) {
          this.load = true
          const params = {
            circular_memo_no: getCircularMemoNo,
            table: 'iab_circular_publications'
          }
          const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularData, params)
          if (!result.success) {
            this.search.training_title_id = 0
          } else {
            this.lcErrorMsg = ''
            const trData = result.data
            this.search.training_title_id = trData.training_title_id
            this.load = false
          }
          this.dateList = []
          this.load = false
        },
        async getCircularList () {
          if (this.search.fiscal_year_id && this.search.org_id) {
            this.circularLoading = true
            const serchData = {
              fiscal_year_id: this.search.fiscal_year_id,
              org_id: this.search.org_id
            }
            const result = await RestApi.getData(trainingElearningServiceBaseUrl, orgCircularList + '/' + 'iab_circular_publications', serchData)
            if (!result.success) {
            } else {
              const listObject = result.data
              const tmpList = listObject.map((obj, index) => {
                if (this.$i18n.locale === 'bn') {
                  return { value: obj.circular_memo_no, text: obj.circular_memo_no }
                } else {
                  return { value: obj.circular_memo_no, text: obj.circular_memo_no }
                }
              })
              this.circularList = tmpList
            }
            this.circularLoading = false
          } else {
            const circularMemo = await RestApi.getData(trainingElearningServiceBaseUrl, '/report/circular-publication/circular-memo')
              if (!circularMemo.success) {
            } else {
                const listObject = circularMemo.data
                const tmpList = listObject.map((obj, index) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.circular_memo_no, text: obj.circular_memo_no }
                } else {
                    return { value: obj.circular_memo_no, text: obj.circular_memo_no }
                }
              })
              this.circularList = tmpList
              // this.titleList = listObject.map((obj) => {
              // const title = this.trainingTitleList.find(el => el.value === parseInt(obj.training_title_id))
              // if (this.$i18n.locale === 'bn') {
              //       return { value: title.value, text: title.text_bn }
              //   } else {
              //       return { value: title.value, text: title.text_en }
              //   }
              // })
            }
            this.circularLoading = false
          }
        },
      details (item) {
        this.item = item
      },
        dataChange () {
            this.loadData()
        },
        remove (item) {
          //   this.changeStatus(trainingElearningServiceBaseUrl, courseManagmentToggleStatus, item, 'training_e_learning', 'trainerEvaluationList')
        },
        // loadData () {
        //   const params = Object.assign({}, this.search)
        //   this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        //   RestApi.getData(trainingElearningServiceBaseUrl, trainerTraineeInfoReportList, params).then(response => {
        //     if (response.success) {
        //       this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
        //     }
        //     this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        //   })
        // },
        searchData () {
          this.loadData()
        },
        async loadData () {
          this.customloading = true
          this.showData = true
          if (this.search.fiscal_year_id) {
            const fiscalYear = this.fiscalYearList.find(obj => obj.value === this.search.fiscal_year_id)
            const customItem = {
              fiscal_year_en: fiscalYear?.text_en,
              fiscal_year_bn: fiscalYear?.text_bn
            }
            this.searchHeaderData = Object.assign({}, this.searchHeaderData, customItem)
          }
          if (this.search.org_id) {
            const orgObj = this.orgList.find(obj => obj.value === this.search.org_id)
            const orgData = {
              org_en: orgObj?.text_en,
              org_bn: orgObj?.text_bn
            }
            this.searchHeaderData = Object.assign({}, this.searchHeaderData, orgData)
          }
          if (this.search.circular_memo_no) {
            const cicularObj = this.circularList.find(obj => obj.value === this.search.circular_memo_no)
            const circularData = {
              circular_memo_no: cicularObj?.text
            }
            this.searchHeaderData = Object.assign({}, this.searchHeaderData, circularData)
          }
          if (this.search.training_title_id) {
            const trainingTitleObj = this.trainingTitleList.find(obj => obj.value === this.search.training_title_id)
            const trainingTitleData = {
              training_title_en: trainingTitleObj?.text_en,
              training_title_bn: trainingTitleObj?.text_bn
            }
            this.searchHeaderData = Object.assign({}, this.searchHeaderData, trainingTitleData)
          }
          if (this.search.batch_id) {
            const batchObj = this.batchList.find(obj => obj.value === this.search.training_title_id)
            const batchData = {
              batch_en: batchObj?.text_en,
              batch_bn: batchObj?.text_bn
            }
            this.searchHeaderData = Object.assign({}, this.searchHeaderData, batchData)
          }

          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          // this.$store.dispatch('mutateCommonProperties', { customloading: true, listReload: false })
          const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainerTraineeInfoReportList, params)
          if (result.success) {
            this.finalList = this.getCustomDataList(result.data)
            this.customloading = false
          } else {
            this.finalList = []
          }
        },
        getCustomDataList (data) {
          const listData = data.map((item, index) => {
            const orgObj = this.$store.state.CommonService.commonObj.componentOrgList.find(doc => doc.value === parseInt(item.org_id))
            const orgData = {}
            if (typeof orgObj !== 'undefined') {
              orgData.org = orgObj.text_en
              orgData.org_bn = orgObj.text_bn
            } else {
              orgData.org = ''
              orgData.org_bn = ''
            }
            const batchObj = this.$store.state.TrainingElearning.commonObj.batchList.find(doc => doc.value === parseInt(item.batch_id))
            const batchData = {}
            if (typeof batchObj !== 'undefined') {
              batchData.batch_name = batchObj.text_en
              batchData.batch_name_bn = batchObj.text_bn
            } else {
              batchData.batch_name = ''
              batchData.batch_name_bn = ''
            }
            const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
            const fiscalYearData = {}
            if (typeof fiscalYearObj !== 'undefined') {
              fiscalYearData.fiscal_year = fiscalYearObj.text_en
              fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
              // fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            } else {
              fiscalYearData.fiscal_year = ''
              fiscalYearData.fiscal_year_bn = ''
            }
            const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
            const trainingTitleData = {}
            if (typeof trainingTitleObj !== 'undefined') {
              trainingTitleData.training_title = trainingTitleObj.text_en
              trainingTitleData.training_title_bn = trainingTitleObj.text_bn
            } else {
              trainingTitleData.training_title = ''
              trainingTitleData.training_title_bn = ''
            }
            const userTypeObj = this.userTypeList.find(item1 => item1.value === parseInt(item.registration_for))
            const userTypeData = {}
            if (typeof userTypeObj !== 'undefined') {
              userTypeData.user_type = userTypeObj.text_en
              userTypeData.user_type_bn = userTypeObj.text_bn
            } else {
              userTypeData.user_type = ''
              userTypeData.user_type_bn = ''
            }
            return Object.assign({}, item, { serial: index }, orgData, batchData, fiscalYearData, trainingTitleData, userTypeData)
          })
          return listData
        },
        async pdfExport () {
          this.loading = true
          const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, fiscal_year: this.search.fiscal_year_id, org_id: this.search.org_id ? this.search.org_id : 9, trainer_trainee: this.search.trainer_trainee, circular_memo_no: this.search.circular_memo_no, training_title_id: this.search.training_title_id, batch_id: this.search.batch_id })
          const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, trainerTraineeInfoReportList, params)
          var blob = new Blob([result], {
            type: 'application/pdf'
          })
          var url = window.URL.createObjectURL(blob)
          window.open(url).print()
          this.loading = false
        }
      }
  }
  </script>
  <style scoped>
  table, th, td {
    border: 1px solid black;
    padding:5px;
  }
</style>
