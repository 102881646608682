<template>
  <b-row>
      <b-col lg="12" sm="12" :show="loader">
          <b-overlay :show="circularLoading">
              <b-row>
                <b-col md="12" class="mb-2 text-center">
                  <p style="font-weight:bold;background-color:#B6D0E2;">{{$t('externalTraining.applicant')}} {{$t('globalTrans.info')}}</p>
                </b-col>
              </b-row>
              <b-row class="mb-3 mx-2 mt-2">
                <table class="table table-borderless w-100 mt-3">
                  <tr>
                    <td class="pr-1" style="width:25%"><strong>{{$t('globalTrans.sl_no')}}</strong></td>
                    <td class="px-0">{{":"}}</td>
                    <td class="pl-1" style="width:30%">{{ item.sl_no }}</td>
                    <td class="pr-1" style="width:25%"><strong>{{$t('externalTraining.applicant')}} {{$t('globalTrans.name')}}</strong></td>
                    <td class="px-0">{{":"}}</td>
                    <td class="pl-1" style="width:30%">{{ ($i18n.locale==='bn') ? item.name_bn : item.name }}</td>
                  </tr>
                  <tr>
                    <td class="pr-1" style="width:25%"><strong>{{$t('globalTrans.organization')}} {{$t('globalTrans.name')}}</strong></td>
                    <td class="px-0">{{":"}}</td>
                    <td class="pl-1" style="width:30%">{{ ($i18n.locale==='bn') ? item.org_name_bn : item.org_name_en }}</td>
                    <td class="pr-1" style="width:25%"><strong>{{$t('globalTrans.organization')}} {{$t('globalTrans.type')}}</strong></td>
                    <td class="px-0">{{":"}}</td>
                    <td class="pl-1" style="width:30%">{{ getOrgName(item.org_type_id) }}</td>
                  </tr>
                  <tr>
                    <td class="pr-1" style="width:25%"><strong>{{$t('globalTrans.designation')}}</strong></td>
                    <td class="px-0">{{":"}}</td>
                    <td class="pl-1" style="width:30%">{{ ($i18n.locale==='bn') ? item.designation_bn : item.designation_en }}</td>
                    <td class="pr-1" style="width:25%"><strong>{{$t('globalTrans.mobile')}}</strong></td>
                    <td class="px-0">{{":"}}</td>
                    <td class="pl-1" style="width:30%">{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(item.mobile, { useGrouping: false }) }}</td>
                  </tr>
                  <tr>
                    <td class="pr-1" style="width:25%"><strong>{{$t('globalTrans.email')}}</strong></td>
                    <td class="px-0">{{":"}}</td>
                    <td class="pl-1" style="width:30%">{{ item.email }}</td>
                    <td class="pr-1" style="width:25%"><strong></strong></td>
                    <td class="px-0"></td>
                    <td class="px-0" style="width:30%"></td>
                  </tr>
                </table>
              </b-row>
              <b-row>
                <b-col md="12" class="mb-2 text-center">
                  <p style="font-weight:bold;background-color:#B6D0E2;">{{$t('tpm_report.application_info')}}</p>
                </b-col>
              </b-row>
              <b-row class="mb-3 mx-2 mt-2">
                <table class="table table-borderless w-100 mt-3">
                  <tr>
                    <td class="pr-1" style="width:25%"><strong>{{$t('elearning_iabm.registration_for')}}</strong></td>
                    <td class="px-0">{{":"}}</td>
                    <td class="pl-1" style="width:30%">{{ ($i18n.locale==='bn') ? item.user_type_bn : item.user_type }}</td>
                    <td class="pr-1" style="width:25%"><strong>{{$t('elearning_iabm.circular_memo_no')}}</strong></td>
                    <td class="px-0">{{":"}}</td>
                    <td class="pl-1" style="width:30%">{{ (item.circular_memo_no) }}</td>
                  </tr>
                  <tr>
                    <td class="pr-1" style="width:25%"><strong>{{ $t('elearning_config.training_title') }}</strong></td>
                    <td class="px-0">{{":"}}</td>
                    <td class="pl-1" style="width:30%">{{ ($i18n.locale==='bn') ? item.training_title_bn : item.training_title }}</td>
                    <td class="pr-1" style="width:25%"><strong>{{ $t('elearning_config.batch') }}</strong></td>
                    <td class="px-0">{{":"}}</td>
                    <td class="pl-1" style="width:30%">{{ ($i18n.locale==='bn') ? item.batch_name_bn : item.batch_name }}</td>
                  </tr>
                  <tr>
                    <td class="pr-1" style="width:25%"><strong>{{ $t('globalTrans.remarks')  }}</strong></td>
                    <td class="px-0">{{":"}}</td>
                    <td class="pl-1" style="width:30%">{{ ($i18n.locale==='bn') ? item.remarks_bn : item.remarks }}</td>
                    <td class="pr-1" style="width:25%"><strong></strong></td>
                    <td class="px-0"></td>
                    <td class="pl-1" style="width:30%"></td>
                  </tr>
                </table>
              </b-row>
          </b-overlay>
      </b-col>
  </b-row>
</template>

<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { complaindetails } from '../../api/routes'
export default {
  name: 'Approve',
  props: ['item'],
  components: {
  },
  data () {
    return {
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      loader: false,
      circularLoading: false,
      valid: null,
      saveBtnName: this.$t('globalTrans.approve'),
      errors: [],
      userData: {},
      formData: {
        resolve: ''
      },
      orgType: [
          {
              value: 1,
              text: 'MOC',
              text_en: 'MOC',
              text_bn: 'এমওসি'
          },
          {
              value: 2,
              text: 'Private',
              text_en: 'Private',
              text_bn: 'ব্যক্তিগত'
          },
          {
              value: 3,
              text: 'Others',
              text_en: 'Others',
              text_bn: 'অন্যান্য'
          }
      ]
    }
  },
  created () {
   if (this.item) {
    this.details()
      this.formData.id = this.item.id
    }
  },
  watch: {
  },
  computed: {
    orgTypeList () {
      const orgTypeList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'MOC' : 'এমওসি', text_en: 'MOC', text_bn: 'এমওসি' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Private' : 'ব্যক্তিগত', text_en: 'Private', text_bn: 'ব্যক্তিগত' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Others' : 'অন্যান্য', text_en: 'Others', text_bn: 'অন্যান্য' }
      ]
      return orgTypeList
    },
    currentLocale () {
      return this.$i18n.locale
    },
    loading: function () {
      return this.$store.state.commonObj.loading
    }
  },
  methods: {
    getOrgName (id) {
      const orgType = this.orgTypeList.find(item => item.value === parseInt(id))
        if (typeof orgType !== 'undefined') {
          return this.$i18n.locale === 'en' ? orgType.text_en : orgType.text_bn
        } else {
          return ''
        }
    },
    getTrainingTitle (id) {
        const dataBankData = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTitleList.find(item => item.value === parseInt(id))
        if (typeof dataBankData !== 'undefined') {
          return this.$i18n.locale === 'en' ? dataBankData.text_en : dataBankData.text_bn
        } else {
          return ''
        }
    },
    getOrg (id) {
      const sectorObj = this.$store.state.CommonService.commonObj.componentOrgList.find(el => el.value === parseInt(id))
      if (sectorObj !== undefined) {
        return this.currentLocale === 'bn' ? sectorObj.text_bn : sectorObj.text_en
      }
    },
    async details () {
          this.circularLoading = true
          const serchData = {
            complain_by: this.item.complain_by
          }
          const result = await RestApi.getData(trainingElearningServiceBaseUrl, complaindetails, serchData)
          if (!result.success) {
              this.userData = {}
          } else {
              this.userData = result.data
          }
          this.circularLoading = false
    }
  }
}
</script>
